<template>
    <div id="category-list-area" class="content-area">
        <div id="category-title-area">
            <div id="category-title-text">
                カテゴリ
            </div>
        </div>
        <div id="category-names-area">
            <div v-for="category in categories">
                <div
                    class="category-name-area link"
                    v-on:click="moveToFilteredArticlesByCategory(category_id=category.id)"
                >
                    <div class="category-name-text">
                        {{category.name}}
                    </div>
                    <div
                        v-if="filterSubcategoriesByCategoryId(category.id).length <= 1"
                        class="category-name-count"
                    >
                        {{category.count}}
                    </div>
                </div>
                <div v-for="subcategory in filterSubcategoriesByCategoryId(category.id)">
                    <div
                        v-if="subcategory.name"
                        class="subcategory-name-area link"
                        v-on:click="moveToFilteredArticlesBySubcategory(subcategory_id=subcategory.id)"
                    >
                        <div class="subcategory-name-text">
                            {{subcategory.name}}
                        </div>
                        <div class="subcategory-name-count">
                            {{subcategory.count}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="yearmonth-list-area" class="content-area">
        <div id="yearmonth-title-area">
            <div id="yearmonth-title-text">
                月別アーカイブ
            </div>
        </div>
        <div id="yearmonth-names-area">
            <div
                v-for="yearmonth in yearmonths"
                v-on:click="moveToFilteredArticlesByYearMonth(year=yearmonth.year, month=yearmonth.month)"
            >
                <div class="yearmonth-name-area link">
                    <div class="yearmonth-name-text">
                        {{yearmonth.year}}/{{yearmonth.month}}
                    </div>
                    <div class="yearmonth-name-count">
                        {{yearmonth.count}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router/index.js';

    export default {
        name: 'BlogSideMenuComponent',
        props: {
            categories: Array,
            subcategories: Array,
            yearmonths: Array
        },
        setup(props) {
            //-----------------------------------------
            // メソッド（APIコールなし・テンプレート内使用あり）
            //-----------------------------------------
            function moveToFilteredArticlesByCategory(category_id) {
                let query_params = {
                    category_id: category_id,
                };
                router.push({name: "blog", query: query_params});
            }

            function moveToFilteredArticlesBySubcategory(subcategory_id) {
                let query_params = {
                    subcategory_id: subcategory_id,
                };
                router.push({name: "blog", query: query_params});
            }

            function moveToFilteredArticlesByYearMonth(year, month) {
                let query_params = {
                    year: year,
                    month: month
                };
                router.push({name: "blog", query: query_params});
            }

            function filterSubcategoriesByCategoryId(category_id) {
                return props.subcategories.filter(subcategory => subcategory.category_id === category_id);
            }

            // -----------------------------------------
            // テンプレートへの返り値
            // -----------------------------------------
            return {
                moveToFilteredArticlesByCategory, moveToFilteredArticlesBySubcategory,
                moveToFilteredArticlesByYearMonth,
                filterSubcategoriesByCategoryId
            };
        }
    }
</script>

<style scoped lang="scss">
    @import '@/scss/components/blog/sideMenu.scss';
</style>
